<template>
  <div>
<!--    <dept-view></dept-view>-->
    <router-view></router-view>
  </div>
</template>


<script>
import DeptView from './views/DeptView.vue'

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { DeptView },
  data() {
    return {

    }
  },
  methods:{

  }
}
</script>
<style>

</style>
