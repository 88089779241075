import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DeptView from "@/views/DeptView.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pic',
    name: 'pic',
    component: DeptView
  }

]

const router = new VueRouter({
  mode: 'history',

  routes
})

export default router
