<template>
  <div class="home">
    <van-nav-bar
        title="展示"
        style="color: dodgerblue"
    />

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div style="padding: 10px">
        <div class="left">
          <div v-for="(pic,i) in picList" :key="i" class="image">
            <div class="card" v-if="i%2==0">
              <img :src="pic.picturePath" class="card-img" mode="widthFix" @click="viewPic(pic.picturePath)">
            </div>
          </div>
        </div>
        <div class="right">
          <div v-for="(pic,i) in picList" :key="i" class="image">
            <div class="card" v-if="i %2==1">
              <img :src="pic.picturePath" class="card-img" mode="widthFix " @click="viewPic(pic.picturePath)">
            </div>
          </div>
        </div>
      </div>

    </van-pull-refresh>
  </div>
</template>

<script>
import request from "@/utils/request";
import {ImagePreview, Toast} from "vant";


export default {
  name: 'HomeView',
  data() {
    return {
      query:{
        pageNo:1,
        pageSize:6,
      },
      picList: [],
      isloading:false, //是否请求数据
      total: 0,
      count: 0,
      isLoading: false,
      finished: false,
      refreshing: false,
    };
  },
  created() {
    // this.readFileInServer()
    this.getPicList()
  },
  methods:{
    async getPicList() {
      this.isloading = true
      await request.get("/api/list").then(res =>{
        this.isloading = false
        if (res.code == 200){
          console.log(res.data)
            this.picList = res.data.list
        }
      })

    },
    async readFileInServer(){
      // 更新服务器数据
     await request.post('/api/read').then(res =>{
       console.log("read请求结果", res)
       if (res.code === 200) {
         console.log("已读取服务器文件夹")
         // this.picList = [],
         // this.total = 0,
         // this.getPicList()
       }
      })

    },
    refresh(){
      console.log("开始刷新")
      this.readFileInServer()
    },
    viewPic(path){
      console.log("放大查看图片",path)
      let arr=[];
      arr.push(path)
      console.log(arr,'图片的地址')
      // const imgUrl = this.pathList; //图片列表，this.imgList是一个图片链接的数组。
      // console.log();
      // this.enlargedImage = imgUrl;
/*      ImagePreview({
        urls: arr,
        current: path
      });*/
      ImagePreview([
        path
      ]);
    },
/*
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
*/
    onRefresh1() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onReachBottom(){
      //判断是否还有下一页数据
      if(this.query.pageNo * this.query.pageSize >= this.total) return Toast('数据加载完毕！')
      //判断是否在请求数据，若是就不发起请求
      if(this.isloading) return
      //页码加一
      this.query.pageNo +=1
      this.getPicList()
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.count++;
        // 先进行读取服务器
        this.readFileInServer()
        this.query.pageNo =1
        this.query.total  =0
        this.isloading = false
        this.picList = []
        console.log("刷新后")
        this.getPicList()
      }, 1000);
    },
  }
}
</script>
<style>
.left,.right{
  display: inline-block;
  vertical-align: top;
  width: 49%;
}
.image{
  background-color: #fff;
  margin: 1%;
  margin-bottom: 20rpx;
  display: inline-block;
  width: 50%;
}
.card-img{
  width: 165px;
}
</style>
