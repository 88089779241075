<template>
  <div class="home">
    <van-nav-bar
        title="展示"
    />

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <p>刷新次数: {{ count }}</p>
    </van-pull-refresh>
  </div>
</template>

<script>
// import { List, Image, Loading } from 'vant';
import request from '@/utils/request'; // 确保你有配置好的请求方法

export default {
  // components: { List, Image, Loading },
  data() {
    return {
      query:{
        pageNo:1,
        pageSize:6,
      },
      picList: [],
      isloading:false, //是否请求数据
      total: 0,
      count: 0,
      isLoading: false,
      finished: false,
      refreshing: false,
    };
  },
  created() {
    // this.readFileInServer()
    this.getPicList()
    console.log(this.picList,"aa")
  },
  methods: {
    async getPicList() {
      this.isloading = true
      request.get("/api/pics").then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.picList = res.data.list
        }
      })
    }
  }
};
</script>

<style scoped>
.pic-page {
  padding: 16px;
}

.pic-item {
  width: 100%;
  height: 200px;
  margin-bottom: 16px;
}
</style>
